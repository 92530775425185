<template>
    <div class="delivery">
        <div class="map-wrapper">
            <div id="map"></div>
            <MapIcons :map="map" :data="iconsData"></MapIcons>
            <v-alert type="info" class="alert elevation-3" :value="alert" :dismissible="true">
                {{ $t('delivery.alert.no_couriers_found') }}
            </v-alert>
        </div>

        <div class="card-wrapper">
            <v-expansion-panels v-model="panel" :disabled="disabled" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-run-fast</v-icon>
                        <span class="text-no-wrap" style="color:#666">В процессе доставки - {{ couriersProcess.length }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p v-if="!couriersProcess.length" class="item-courier-empty">нет курьеров</p>
                        <p v-for="(courier, i) of couriersProcess" :key="courier.id" class="item-courier" v-ripple @click="onClickCourier(courier)">
                            {{ i + 1 }}. {{ courier.tname }}
                            <v-menu open-delay="750" open-on-hover :close-on-content-click="false" bottom offset-y v-for="order of courier.orders" :key="order.id">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="item-order d-inline-block" :style="orderStatusStyle(order)">{{ order.name }}</span>
                                </template>

                                <v-card class="mx-auto" min-width="120" max-width="400" outlined>
                                    <div class="order-card" v-html="orderContent(order)"></div>
                                </v-card>
                            </v-menu>
                        </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-check</v-icon>
                        <span class="text-no-wrap" style="color:#666">Свободные курьеры - {{ couriersFree.length }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p v-if="!couriersFree.length" class="item-courier-empty">нет курьеров</p>
                        <p v-for="(courier, i) of couriersFree" :key="courier.id" class="item-courier" v-ripple @click="onClickCourier(courier)">{{ i + 1 }}. {{ courier.tname }}</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-information-outline</v-icon>
                        <span class="text-no-wrap" style="color:#666">Статусы заказов</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-chip v-for="status of orderStatuses" :key="status.text" :color="status.color" text-color="white" class="elevation-2">
                            <v-icon size="small" class="mr-1">{{ status.icon }}</v-icon>
                            {{ status.text }}
                        </v-chip>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="overline">
                        <v-icon class="d-block flex-grow-0 mr-3" color="blue">mdi-message-text-outline</v-icon>
                        <span class="text-no-wrap" style="color:#666">Маркеры на карте</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row class="mx-auto">
                            <v-col cols="12" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.ordersComplete" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-check-bold</v-icon> Выполненные заказы</div>
                                    </template>
                                </v-checkbox>
                            </v-col>

                            <v-col cols="6" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.orders" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-inbox-multiple</v-icon> Заказы</div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            
                            <v-col cols="6" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.partners" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-home-group</v-icon> Партнеры</div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            
                            <v-col cols="6" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.couriers" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-car-multiple</v-icon> Курьеры</div>
                                    </template>
                                </v-checkbox>
                            </v-col>

                            <v-col cols="6" class="pa-1">
                                <v-checkbox v-model="$store.state.showMarkers.arrows" hide-details class="mt-2" @click="markersUpdate">
                                    <template v-slot:label>
                                        <div><v-icon class="mx-1">mdi-arrow-expand</v-icon> Стрелки</div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
    import * as L from 'leaflet'
    // import polylineDecorator from 'leaflet-polylinedecorator'
    import 'leaflet-arrowheads'
    // import textPath from 'leaflet-textpath'
    import 'leaflet/dist/leaflet.css'
    const config = require('../../config')
    import moment from 'moment-timezone'

    moment.locale('ru')
    moment.tz.setDefault(config.timezoneString)

    import MapObject from '../../mixins/MapObject.js'
    import MapIcons from './MapIcons'

    // метод для копирования и слияния - полезный!
    // var obj = { a: 1 };
    // var copy = Object.assign({}, obj);

    /* leaflet icon */
    delete L.Icon.Default.prototype._getIconUrl
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    })

    export default {
        mixins: [MapObject],
        data() {
            return {
                map: null,
                sticky: false,
                alert: false,
                orders: [],
                partners: [],
                couriers: [],
                couriersProcess: [],
                couriersFree: [],
                options: {},
                object_markers: [],
                object_markers_created: false,
                object_selected: 0,
                popup: null,
                timer: null,
                panel: [],
                disabled: false,
                marker_opened: null,
                marker_with_popup: null,
                marker_over: false,
                polylines: [],
                arrows: [],
                iconsData: [],
                iconsData1: [],
                iconsData2: [],
                orderStatuses: [
                    {
                        text: 'Новый',
                        color: '#9c27b0', // purple
                        icon: 'mdi-flash',
                    },
                    {
                        text: 'Ожидание курьера',
                        color: '#ff9800', // orange
                        icon: 'mdi-run-fast',
                    },
                    {
                        text: 'В пути к гостю',
                        color: '#2196f3', // blue
                        icon: 'mdi-cube-send',
                    },
                    {
                        text: 'Доставлен',
                        color: '#4caf50', // green
                        icon: 'mdi-check-bold',
                    },
                    {
                        text: 'Создается',
                        color: '#607d8b', // blue-grey
                        icon: 'mdi-account-question',
                    },
                    {
                        text: 'Передан курьеру',
                        color: '#f44336', // red
                        icon: 'mdi-arrow-right-bold',
                    },
                ],
            }
        },

        components: {
            MapIcons,
        },

        mounted() {
            console.log('!!! delivery mounted !!!')
            // this.init()
            this.timer = setTimeout(() => {
                this.init()
            }, 10)
            // this.MapObjectHello()
        },
        destroyed() {
            console.log('!!! delivery destroyed !!!')
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.mapObjectsMarkersDelete()
        },
        methods: {
            timeToString(date) {
                const isToday = moment().isSame(moment(date), 'day')
                let dateRes = ''
                if (isToday) {
                    dateRes = moment(date).format('сегодня HH:mm')
                } else {
                    dateRes = moment(date).format('D MMM HH:mm')
                }
                return dateRes
            },

            orderBodyText(order) {
                let res = `🏪 Откуда: <b>${order.start_addr.replace('\n', ' ')}</b><br>`
                res += `🏡 Куда: <b>${order.finish_addr.replace('\n', ' ')}</b><br>`
                res += `🎫 Гость: <b>${order.guest.replace('\n', ' ')}</b><br>`
                res += `🧾 Способ оплаты: <b>${order.pay.replace('\n', ' ')}</b><br>`
                res += `💵 Сумма заказа: <b>${order.pay_cost.toString().replace('\n', ' ')}</b><br>`
                res += `${order.mode}: <b>${order.mode_time.replace('\n', ' ')}</b><br>`
                res += order.descr == '' ? '' : `💬 Комментарий: <b>${order.descr.replace('\n', ' ')}</b><br>`
                res += `🕓 Создан: <b>${this.timeToString(order.date)}</b><br>`
                return res
            },

            orderFullText(order) {
                let text = ''
                // console.log('orderFullText order', order)
                let courierStr = 'нет'
                let status = 'не обработан ❗️'
                courierStr = order.courier_name
                if (order.status == config.order.status.way_partner) {
                    status = 'в пути к заведению'
                    // status = 'в пути к заведению (1.3 км)'
                    // let addr = 'адрес не определен'
                    // courierStr += ` (${addr})`
                } else if (order.status == config.order.status.way_guest) {
                    status = 'в пути к гостю'
                    // status = 'в пути к гостю (0.4 км)'
                    // let addr = 'адрес не определен'
                    // courierStr += ` (${addr})`
                } else if (order.status == config.order.status.passed) {
                    status = 'передан курьеру (ожидание ответа)'
                } else if (order.status == config.order.status.complete) {
                    status = 'доставлен ✅'
                }

                text += `📦 <b>${order.name}</b><br>`
                text += this.orderBodyText(order)
                if ((order.status == config.order.status.complete || order.status == config.order.status.way_partner || order.status == config.order.status.way_guest || order.status == config.order.status.passed) && order.date_accept) {
                    text += `🕓 Отправлен: <b>${this.timeToString(order.date_accept)}</b><br>`
                }
                if ((order.status == config.order.status.complete || order.status == config.order.status.way_partner || order.status == config.order.status.way_guest || order.status == config.order.status.passed) && order.date_accept && order.date_take) {
                    text += `🚩 Забран: <b>${this.timeToString(order.date_take)}</b><br>`
                }
                if (order.status == config.order.status.complete && order.date_complete) {
                    text += `🏁 Доставлен: <b>${this.timeToString(order.date_complete)}</b><br>`
                }
                text += `🔅 Статус: <b>`
                switch (order.status) {
                    case config.order.status.new: {
                        text += 'не обработан ❗️'
                        break
                    }
                    case config.order.status.way_partner: {
                        text += 'в пути к заведению 🛵'
                        break
                    }
                    case config.order.status.way_guest: {
                        text += 'в пути к гостю 🛵'
                        break
                    }
                    case config.order.status.complete: {
                        text += 'доставлен ✅'
                        break
                    }
                    case config.order.status.forming: {
                        text += 'формируется ⏳'
                        break
                    }
                    case config.order.status.passed: {
                        text += 'передан курьеру ⏳'
                        break
                    }
                }
                text += `</b><br>`
                if (order.status == config.order.status.complete || order.status == config.order.status.way_partner || order.status == config.order.status.way_guest || order.status == config.order.status.passed) {
                    text += `🛵 Курьер: <b>${courierStr}</b><br>`
                }
                return text
            },

            orderContent(order) {
                let html = this.orderFullText(order)
                return html
            },
            onClickCourier(courier) {
                console.log('onClickCourier', courier.tname)

                this.map.setView([courier.object.data.glat, courier.object.data.glng], 16)
            },
            avatarIcon(courier) {
                return new L.DivIcon({
                    html: this.avatarIconContent(courier),
                })
            },
            avatarIconContent(courier) {
                let html = `<div class="avatar">
                    <img class="avatar-shadow" src="${require('../../assets/images/avatar-shadow.png')}">
                    <img class="avatar-icon" src="${config.api.delivery + 'user_avatar?tid=' + courier.tid}">
                `
                for (let i = 0; i < courier.orders.length; i++) {
                    const order = courier.orders[i]
                    html += `<div style="right: ${-25 - i * 13}px; background-color: ${this.orderStatusColorHtml(order.status)}" class="avatar-status avatar-status-pos-${i}"></div>`
                }
                html += `
                </div>`
                return html
            },
            init() {
                this.map = L.map('map', {
                    center: [43.121284, 131.901917],
                    zoom: 14,
                    zoomControl: true,
                    doubleClickZoom: true,
                    attributionControl: false,
                })
                L.tileLayer(config.map.url).addTo(this.map)

                console.log('delivery init', this.map)

                // map.on('movestart', onMapMoveStart);
                // map.on('moveend', onMapMoveEnd);
                // map.on('dragend', onMapDragEnd);
                // map.on('dragstart', onMapDragStart);
                // map.on('viewreset', onMapViewReset);
                // map.on('zoomstart', onMapZoomStart);
                // map.on('zoomend', onMapZoomEnd);
                // map.on('click', onMapMouseClick);

                this.map.on('popupclose', this.onMapPopupClose)
                this.map.on('popupopen', this.onMapPopupOpen)

                this.map.on('click', e => {
                    console.log('⚡️ click map')
                    this.onMapMouseClick(e)
                })

                this.map.on('mousedown', e => {
                    console.log('⚡️ mouseup mousedown')
                })

                this.map.on('mouseup', e => {
                    console.log('⚡️ mouseup map')
                })

                this.map.on('move', e => {
                    console.log('⚡️ move map')
                })

                this.map.on('keydown', e => {
                    console.log('keydown', e.originalEvent.code)
                })

                this.markersUpdate()

            },

            markersUpdate() {
                this.$store.commit('showMarkers', this.$store.state.showMarkers)
                // if (this.timer) {
                //     clearTimeout(this.timer)
                //     this.timer = null
                // }
                this.mapObjectsUpdate()
                this.partnersUpdate()
            },

            partnersUpdate() {
                    this.$store.dispatch('partners_get').then(res => {
                        if (res.data.success) {
                            this.partners = res.data.partners
                            this.iconsData2 = []
                            if (this.$store.state.showMarkers.partners) {
                                this.partners.forEach(partner => {
                                    let coordinates = JSON.parse(partner.coordinates)
                                    if (coordinates) {
                                        const this_ = this
                                        this.iconsData2.push({
                                            id: partner.id,
                                            color: '#233e6d',
                                            content: '🏪 ' + (partner.name.length > 15 ? partner.name.substr(0, 15) + '...' : partner.name),
                                            lat: +coordinates[1] + (0.5 - Math.random())/5000,
                                            lon: +coordinates[0] + (0.5 - Math.random())/5000,
                                            options: {
                                                data: partner,
                                            },
                                            tooltip: function () {
                                                return `
                                                🏪 <b>${ this.options.data.name }</b><br>
                                                🏡 Адрес: <b>${ this.options.data.address.replace('\n', ' ') }</b><br>
                                                
                                                `
                                            }
                                        })
                                    }
                                })
                            }
                            this.iconsData = this.iconsData1.concat(this.iconsData2)
                        }
                    })
            },


            mapObjectsUpdate() {
                this.$store
                    .dispatch('couriers_get')
                    .then(res => {
                        if (res.data.success) {
                            const user = this.$store.state.user
                            console.log('user', user)
                            console.log('couriers_get length', res.data.couriers.length)
                            const params = {
                                uid: user.mynex_uid,
                                token: user.mynex_token,
                                series: user.mynex_series,
                            }

                            this.$store.dispatch('orders_date', {
                                date1: moment().format('YYYY-MM-DD'),
                                date2: moment().format('YYYY-MM-DD') + ' 23:59:59',
                                partner: 0,
                                courier: 0,
                                mode: 0,
                                deliveryTime: true
                            }).then(res => {
                                if (res.data.success) {

                                    this.orders = res.data.orders
                                    // console.log('this.orders', this.orders)
                                    
                                    this.iconsData1 = []
                                    let orders = []
                                    if (this.$store.state.showMarkers.ordersComplete && this.$store.state.showMarkers.orders) {
                                        orders = this.orders
                                    } else {
                                        if (this.$store.state.showMarkers.ordersComplete) {
                                            orders = this.orders.filter(order => order.status == config.order.status.complete)
                                        } else if (this.$store.state.showMarkers.orders) {
                                            orders = this.orders.filter(order => order.status != config.order.status.complete)
                                        }
                                    }
                                    // console.log('order all', orders)
                                    orders.forEach(order => {
                                        let coordinates = JSON.parse(order.coordinates)
                                        if (coordinates) {
                                            const this_ = this
                                            let orderName = order.mode.substr(0,2) + ' '
                                            if (order.name.length > 20) {
                                                orderName += order.name.substr(0,20) + '...'
                                            } else {
                                                orderName += order.name
                                            }

                                            this.iconsData1.push({
                                                id: order.id,
                                                color: this.orderStatusColorHtml(order.status),
                                                content: orderName,
                                                lat: coordinates[1],
                                                lon: coordinates[0],
                                                options: {
                                                    data: order,
                                                },
                                                tooltip: function () {
                                                    return `<div style="background-color: ${ this_.orderStatusColorHtml(this.options.data.status) }" class="order-id-status">${ this.options.data.id }</div>${ this_.orderFullText(this.options.data) }`
                                                }
                                            })
                                        } else {
                                            console.log('order without coordinates', order)
                                        }
                                    })
                                    this.iconsData = this.iconsData1.concat(this.iconsData2)


                                }
                            })

                            this.$http
                                .get(config.api.mynex + 'object/list', { params })
                                .then(res1 => {
                                    this.couriers = res.data.couriers.filter(courier => courier.enable)
                                    this.couriersProcess = this.couriers.filter(courier => courier.orders.length)
                                    this.couriersFree = this.couriers.filter(courier => !courier.orders.length)
                                    console.log('this.couriers', this.couriers)
                                    let objects = []
                                    if (res1.data.response.success) {
                                        objects = res1.data.response.data
                                    }
                                    // console.log('objects', objects)


                                    this.polylines.forEach(polyline => {
                                        polyline.deleteArrowheads()
                                        this.map.removeLayer(polyline)
                                    })

                                    let i = 0
                                    for (let courier of this.couriers) {
                                        let obj = {
                                            name: courier.tname,
                                            ident: 0,
                                            oid: courier.tid,
                                            online: false,
                                            stime: 0,
                                            data: {
                                                mqua: 0,
                                                glat: 43.113378 + i * 0.003,
                                                glng: 131.85208 + i * 0.0015,
                                            },
                                        }
                                        for (let object of objects) {
                                            // console.log('courier', i, courier.tname, courier.mynex_ident, object.ident)
                                            if (courier.mynex_ident && courier.mynex_ident == object.ident) {
                                                console.log(i, courier.tname, courier.mynex_ident, object.ident)
                                                if (!object.data) {
                                                    object.data = {
                                                        mqua: 0,
                                                        glat: 43.113378 + i * 0.003,
                                                        glng: 131.85208 + i * 0.0015,
                                                    }
                                                }
                                                obj = object
                                                i--
                                                break
                                            }
                                            // console.log('end iter', i, obj)
                                        }
                                        // console.log('for end')
                                        courier.object = obj



                                        if (this.$store.state.showMarkers.arrows) {

                                            for (let order of courier.orders) {
                                                // console.log('print order of courier', courier.tname, order)
                                                let latlngs = []
                                                latlngs.push([courier.object.data.glat, courier.object.data.glng])

                                                if (order.status == config.order.status.way_guest || order.status == config.order.status.passed) {
                                                    const coordinates = JSON.parse(order.coordinates)
                                                    if (coordinates && coordinates[1] && coordinates[0]) {
                                                        latlngs.push([+coordinates[1], +coordinates[0]])
                                                    }
                                                } else if (order.status == config.order.status.way_partner) {
                                                    // console.log('partners', this.partners)
                                                    // console.log('find partner', order.partner_id)
                                                    const partner = this.partners.find(partner => partner.id == order.partner_id)
                                                    // console.log('partner', partner)
                                                    if (partner) {
                                                        const coordinates = JSON.parse(partner.coordinates)
                                                        if (coordinates) {
                                                            latlngs.push([+coordinates[1], +coordinates[0]])
                                                        }
                                                    }
                                                }
                                                console.log('latlngs', latlngs)

                                                let p = L.polyline(latlngs, {
                                                    color: '#444',
                                                    weight: 2,
                                                    dashArray: '10, 10', dashOffset: '0',
                                                })
                                                p.arrowheads({
                                                    yawn: 25,
                                                    size: '20px',
                                                    fill: true,
                                                    stroke: false,
                                                })
                                                this.polylines.push(p)
                                                p.addTo(this.map)
                                                p.getArrowheads().addTo(this.map)
                                            }
                                            // L.featureGroup(this.arrows).addTo(this.map)
                                        }
                                        i++
                                    }

                                    if (this.$store.state.showMarkers.couriers) {
                                        if (!this.object_markers_created) {
                                            this.object_markers_created = true
                                            this.mapObjectsMarkersCreate()
                                        }
                                        this.mapObjectsMarkersUpdate(false)
                                    } else {
                                        if (this.object_markers_created) {
                                            this.object_markers_created = false
                                            this.mapObjectsMarkersDelete()
                                        }
                                    }
                                    if (this.timer) {
                                        this.timer = setTimeout(() => {
                                            this.mapObjectsUpdate()
                                        }, 5000)
                                    }

                                    // this.timer = setTimeout(() => {
                                    //     this.mapObjectsUpdate()
                                    // }, 5000)
                                })
                                .catch(err => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            mapObjectsMarkersCreate() {
                this.couriers.forEach((courier, i) => {
                    const object = courier.object
                    var data = 0
                    try {
                        data = object.data
                    } catch (e) {
                        data = []
                    }

                    if (data) {
                        if (!data.gspe) data.gspe = 0
                        if (!data.pext) data.pext = 0
                        if (!data.gsat) data.gsat = 0
                        let nocoord = false
                        if (!data.glat) {
                            data.glat = 43.073378
                            nocoord = true
                        }
                        if (!data.glng) {
                            data.glng = 131.98208
                            nocoord = true
                        }
                        if (!data.gcou) {
                            data.gcou = 110
                            nocoord = true
                        }

                        let icon = this.avatarIcon(courier)
                        this.object_markers[i] = L.marker([object.data.glat, object.data.glng], {
                            icon,
                            zIndexOffset: 2000,
                            oid: object.oid,
                            ident: object.ident,
                            orders: courier.orders,
                            index: i,
                            speed: data.gspe.toFixed(1),
                            course: data.gcou,
                            online: object.online,
                            mqua: data.mqua,
                            sats: data.gsat,
                            popup_open: false,
                            popup_fix: false,
                            offset_orig: 2000,
                        }).addTo(this.map)

                        this.object_markers[i]
                            .bindTooltip('', {
                                permanent: true,
                                direction: 'right',
                                offset: [32, -30],
                                zIndexOffset: 1000,
                                offset_orig: 1000,
                            })
                            .addTo(this.map)

                        this.object_markers[i].on('click', this.onDeviceMarkerMouseClick)
                        this.object_markers[i].on('mouseover', this.onDeviceMarkerMouseOver)
                        this.object_markers[i].on('mouseout', this.onDeviceMarkerMouseOut)

                        let this_ = this
                        this.object_markers[i].content = function() {
                            let content

                            console.log('this_.couriers', this_.couriers)
                            console.log('this.options.index', this.options.index)

                            content = '<big>🛵 <u>' + this_.couriers[this.options.index].tname + '</u></big>&nbsp;&nbsp;'
                            // content = '<big>🛵 <u>' + this_.couriers[this.options.index].object.name + '</u></big>&nbsp;&nbsp;'
                            content += '<small>'

                            if (!this.options.ident) {
                                content += '<span class="label-color-red">нет трекера</span>'
                            } else if (!this.options.online) {
                                content += '<span class="label-color-red">нет связи</span>'
                            } else {
                                content += '<span class="label-color-green">на связи</span>'
                            }
                            content += '</small><br><br>'

                            if (this.options.orders.length == 0) {
                                content += '✅ Свободен<br>'
                            } else {
                                content += `📦 Доставляет (${ this.options.orders.length }):<br>`
                                content += `<div class="popup-orders">`
                                for (let order of this.options.orders) {
                                    content += `<div class="popup-order-status"><div style="background-color: ${ this_.orderStatusColorHtml(order.status) }" class="order-id-status">${ order.id }</div>${ order.name }</div>`
                                }
                                content += '</div>'
                            }
                            content += '<br>'

                            let t1 = moment().toDate()
                            let t2 = moment(this_.couriers[this.options.index].object.stime).toDate()
                            let dt = Math.ceil(Math.abs(t2.getTime() - t1.getTime()) / 1000)
                            let sec_str
                            if (!this.options.ident) {
                                sec_str = 'местоположение недоступно'
                            } else {
                                if (isNaN(dt)) {
                                    sec_str = 'нет данных о местоположении'
                                } else {
                                    sec_str = 'местоположение получено ' + this_.getSecondsStr(dt, false) + ' назад'
                                }
                            }
                            content += '<small><span class="label-color-grey">' + sec_str + '</span></small><br>'
                            return content
                        }
                    }
                })
                // this.map.setView([this.objects[0].data.glat, this.objects[0].data.glng], 18);
            },

            mapObjectsMarkersDelete() {
                if (this.map) {
                    for (var i = 0; i < this.object_markers.length; i++) {
                        if (this.object_markers[i]) {
                            try {
                                this.map.removeLayer(this.object_markers[i])
                            } catch (e) {
                                console.log('ERROR mapObjectsMarkersDelete', i, this.object_markers[i], e)
                            }
                        }
                    }
                }
                this.object_markers = []
            },

            mapObjectsMarkersUpdate() {
                let object_index = -1
                if (this.object_selected) {
                    object_index = this.couriers.findIndex(courier => courier.object.oid == this.object_selected)
                }
                this.couriers.forEach((courier, i) => {
                    const object = courier.object

                    let offset = 2000
                    if (i == object_index) offset = 3000
                    let data = 0
                    try {
                        data = object.data
                    } catch (e) {
                        1
                    }

                    if (!data || !data.glat || !data.glng) {
                        return
                    }

                    if (data) {
                        let marker = {}
                        try {
                            marker = this.object_markers[i]
                        } catch (e) {
                            this.mapObjectsMarkersDelete()
                            this.mapObjectsMarkersCreate()
                            marker = this.object_markers[i]
                        }

                        if (marker) {
                            if (!data.gspe) data.gspe = 0
                            if (!data.pext) data.pext = 0
                            if (!data.gsat) data.gsat = 0
                            if (!data.gcou) data.gcou = 0
                            {
                                if (!data.glat) {
                                    data.glat = 43.073378
                                }
                                if (!data.glng) {
                                    data.glng = 131.98208
                                }
                                if (!data.gcou) {
                                    data.gcou = 110
                                }
                            }

                            marker.options.speed = data.gspe.toFixed(1)

                            marker.setLatLng([data.glat, data.glng])

                            if (this.marker_opened != marker) marker.setZIndexOffset(offset)
                            marker.options.pext = data.pext.toFixed(1)
                            marker.options.sats = data.gsat
                            marker.options.course = data.gcou
                            marker.options.online = object.online
                            marker.options.mqua = data.mqua
                            marker.options.offset_orig = offset
                            marker.options.stime = object.stime
                            marker.options.orders = courier.orders
                        }

                        let t1 = moment().toDate()
                        let t2 = moment(object.stime).toDate()
                        let dt = Math.ceil(Math.abs(t2.getTime() - t1.getTime()) / 1000)
                        let cl = 'status-color-green'
                        if (!object.ident) {
                            cl = 'status-color-grey'
                        } else if (!object.online || (object.ping > 0 && dt > object.ping * 2)) {
                            cl = 'status-color-red'
                        }
                        let name = `<div class="status ${cl}"></div><span class="label-name">${ courier.tname }</span>`
                        // let name = `<div class="status ${cl}"></div><span class="label-name">${ object.name }</span>`
                        if (marker) {
                            marker._icon.innerHTML = this.avatarIconContent(courier)
                            marker.setTooltipContent(name)
                            marker.update()
                        }
                        if (marker == this.marker_opened) {
                            this.popup.setContent(marker.content())
                            this.popup.setLatLng([data.glat, data.glng])
                            this.popup.update()
                        }
                    }
                })
            },

            onMapMouseClick(e) {
                if (e.originalEvent.target.nodeName !== 'path') {
                    if (this.marker_opened) {
                        this.popup_close(this.marker_opened, true)
                    }
                }
            },

            // События с попапами (открытие и закрытие)
            onMapPopupClose(e) {
                // Событие закрытия отрытого попапа на карте (e)
                if (!this.marker_over) {
                    if (this.marker_opened) this.popup_close(this.marker_opened, false)
                }
            },

            onMapPopupOpen(e) {
                this.marker_with_popup = this.marker_opened
            },

            popup_open(marker, autopan, offsety) {
                let option
                if (autopan) {
                    option = {
                        offset: [0, -offsety],
                        autoPanPaddingTopLeft: [50, 90],
                        autoPanPaddingBottomRight: [50, 0],
                        closeButton: false,
                        closeOnClick: false,
                    }
                } else {
                    option = {
                        offset: [0, -offsety],
                        autoPan: false,
                        closeButton: false,
                        closeOnClick: false,
                    }
                }
                this.popup = L.popup(option)
                    .setLatLng(marker.getLatLng())
                    .setContent(marker.content())
                    .openOn(this.map)

                marker.options.popup_open = true
                marker.options.popup_fix = false
                this.marker_opened = marker
                this.object_selected = marker.options.oid
                if (marker.setZIndexOffset) marker.setZIndexOffset(5000)
            },

            popup_close(marker, popup_close) {
                if (marker) {
                    // if (marker.options.micon) marker.setIcon(marker.options.micon)
                    if (popup_close) this.map.closePopup(this.popup)
                    marker.options.popup_open = false
                    marker.options.popup_fix = false
                    if (marker.setZIndexOffset) marker.setZIndexOffset(marker.options.offset_orig)
                    this.marker_opened = null
                    this.object_selected = 0
                }
            },

            popup_fix_change(marker, offsety) {
                if (marker.options.micon) {
                    offsety = marker.options.icon.options.iconAnchor[1] + 1
                    if (marker.options.rotationAngle && marker.options.rotationAngle == 180) offsety = 6
                }
                if (marker.options.popup_fix) {
                    // Попап закреплен - убираем закрепление и кнопку закрытия на попапе
                    this.popup = L.popup({
                        offset: [0, -offsety],
                        autoPan: false,
                        closeButton: false,
                        closeOnClick: false,
                    })
                        .setLatLng(marker.getLatLng())
                        .setContent(marker.content())
                        .openOn(this.map)
                    marker.options.popup_fix = false
                } else {
                    // Попап не закреплен - устанавливаем закрепление и кнопку закрытия на попапе
                    this.popup = L.popup({
                        offset: [0, -offsety],
                        autoPanPaddingTopLeft: [50, 90],
                        autoPanPaddingBottomRight: [50, 0],
                        closeButton: true,
                        closeOnClick: false,
                    })
                        .setLatLng(marker.getLatLng())
                        .setContent(marker.content())
                        .openOn(this.map)
                    marker.options.popup_fix = true
                }
            },

            // События с маркерами объектов
            onDeviceMarkerMouseClick(e) {
                // console.log('onDeviceMarkerMouseClick', e)

                if (e.target.options.oid) {
                    let index = e.target.options.index
                    let object_index = this.couriers.findIndex(courier => courier.object.oid == this.object_selected)

                    if (index != object_index) {
                        this.popup_close(this.marker_opened, true)
                    }
                    var offsety = 56
                    if (this.marker_over) {
                        if (this.marker_opened && e.target == this.marker_opened) this.popup_fix_change(e.target, offsety)
                        else {
                            this.popup_close(this.marker_opened, true)
                            this.popup_open(e.target, true, offsety)
                            this.popup_fix_change(e.target, offsety)
                        }
                    } else {
                        // Only for tap of touch
                        if (this.marker_opened && e.target == this.marker_opened) this.popup_close(this.marker_opened, true)
                        else this.popup_open(e.target, true, offsety)
                    }
                }
            },

            onDeviceMarkerMouseOver(e) {
                if (!this.marker_over) {
                    this.marker_over = true
                }
                if (e.target.options.popup_open) {
                    // Наводим на маркер с открытым попапом
                } else {
                    var offsety = 56
                    // Наводим на маркер с закрытым попапом
                    if (!this.marker_opened || this.marker_opened.options.popup_fix == false) {
                        // если не зафиксирован открытый попап - открываем попап без кнопки закрытия
                        if (this.marker_opened) this.popup_close(this.marker_opened, true)
                        this.popup_open(e.target, false, offsety)
                    }
                }
            },

            onDeviceMarkerMouseOut(e) {
                if (e.target.options.popup_open) {
                    // Убираем указатель мыши с маркера с открытым попапом
                    if (!e.target.options.popup_fix) {
                        // Попап не зафиксирован - закрываем его попап и убираем фиксацию
                        this.popup_close(e.target, true)
                    }
                    if (this.marker_over) {
                        this.marker_over = false
                    }
                }
            },

            getSecondsStr(seconds, hide_sec = false) {
                let time = ''
                let years = 0
                let days = 0
                let hours = 0
                let mins = 0
                let l = 0
                let s = 0

                if (seconds >= 60) {
                    if (seconds >= 3600) {
                        if (seconds >= 86400) {
                            if (seconds >= 31536000) {
                                years = parseInt(seconds / 31536000)
                                seconds -= years * 31536000
                                l = years.toString().slice(-1)
                                if (l == 1) {
                                    time += years + ' год '
                                } else if (l > 0 && l < 5) {
                                    time += years + ' года '
                                } else {
                                    time += years + ' лет '
                                }
                            }
                            days = parseInt(seconds / 86400)
                            seconds -= days * 86400
                            l = days.toString().slice(-1)
                            if (days == 0) time += days + ' д. '
                            else if (days >= 11 && days <= 14) {
                                time += days + ' дней '
                            } else if (l == 1) {
                                time += days + ' день '
                            } else if (l > 0 && l < 5) {
                                time += days + ' дня '
                            } else {
                                time += days + ' дней '
                            }
                        }
                        hours = parseInt(seconds / 3600)
                        seconds -= hours * 3600
                        if (hours > 0 && years == 0 && days < 3) time += hours + ' ч. '
                    }
                    mins = parseInt(seconds / 60)
                    seconds -= mins * 60
                    if (years == 0 && days == 0 && mins > 0 && hours < 12) time += mins + ' мин. '
                    if (!hide_sec) {
                        if (days == 0 && hours == 0 && mins < 30) {
                            s = parseInt(seconds)
                            if (s < 0) s = 0
                            if (s) {
                                time += s + ' сек.'
                            }
                        }
                    }
                } else {
                    if (hide_sec) time = 'менее минуты'
                    else {
                        s = parseInt(seconds)
                        if (s < 0) s = 0
                        if (s) {
                            time += s + ' сек.'
                        }
                    }
                }
                return time
            },

            orderStatusColorHtml(sta) {
                let color = 'grey'
                switch (sta) {
                    case config.order.status.new:
                        color = '#9C27B0' // purple
                        break
                    case config.order.status.way_partner:
                        color = '#FF9800' // orange
                        break
                    case config.order.status.way_guest:
                        color = '#2196F3' // blue
                        break
                    case config.order.status.complete:
                        color = '#4CAF50' // green
                        break
                    case config.order.status.forming:
                        color = '#607D8B' // blue-grey
                        break
                    case config.order.status.passed:
                        color = '#F44336' // red
                        break
                }
                return color
            },
            orderStatusStyle(order) {
                return {
                    backgroundColor: this.orderStatuses[order.status].color,
                }
            },
        },
    }
</script>

<style lang="stylus">

    .v-expansion-panel-content
        height 240px
        overflow-y auto
        margin-bottom 20px

    .delivery
        height 100%
        #map
          width 100%
          height 100%
          z-index 1

        .map-wrapper
            width 100%
            height 100%
            position absolute
            display flex

        .card-wrapper
            position fixed
            top 90px
            right 20px
            width 400px
            display:flex
            flex-direction column
            z-index 1

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px


        .status
            width 6px
            height 6px
            display inline-block
            margin-right 4px
            margin-bottom 2px

        .status-color-red
            background-color red

        .status-color-green
            background-color green

        .status-color-grey
            background-color grey

        .label-color-red
            color red

        .label-color-green
            color green

        .label-color-grey
            color grey

        .leaflet-div-icon
            border none
            background none

        .popup-orders
            display flex
            flex-direction column

        .popup-order-status
            display flex
            padding 2px 0

        .order-id-status
            display initial
            position initial
            border-radius 2px
            padding 0px 2px
            margin 0
            margin-right 6px
            color white
            font-size 12px
            font-weight 600
            box-shadow 1px 1px 1px grey
            cursor default


        .avatar-icon
            width 52px
            height 52px
            top -52px
            left -20px
            position absolute
            border-radius 50%

        .avatar-disabled
            filter brightness(300%)
            filter sepia(100%)
            // filter grayscale(100%)
            filter gray
            filter blur(0.5em)

        .avatar-shadow
            width 64px
            height 64px
            top -54px
            left -26px
            position absolute

        .avatar-status
            width 20px
            height 20px
            border-radius 50%
            top -55px
            right -25px
            position absolute
            border solid 1px white
            box-shadow 0 0 1px black

        .label-name
            font-weight bold
            font-size 14px !important

        .leaflet-tooltip
            background-color rgba(220, 220, 220,0.7)
            color #333
            box-shadow none
            margin 0 0px
            padding 0
            border none
            border-radius 2px
            font-size 12px
            padding 1px 3px
            white-space nowrap
            text-shadow 1px 1px 2px white, -1px -1px 1px white
            z-index 1
            font-weight normal

        .leaflet-tooltip-top::before, .leaflet-tooltip-bottom::before, .leaflet-tooltip-left::before, .leaflet-tooltip-right::before
            display none

        .leaflet-tooltip-pane
            z-index 550
            
        .leaflet-popup-content-wrapper
            border-radius 4px
            font-size 13px

        .leaflet-popup-content
            margin 12px 14px

        .item-courier
            color #333
            cursor pointer
            font-size 14px
            font-weight 500
            padding 5px
            margin 0
            &:hover
                background-color #f0f0f0


        .item-order
            background-color grey
            color white
            cursor default
            margin 1px
            padding 2px 3px
            border-radius 2px
            font-size 12px
            font-weight 600
            box-shadow 1px 1px 1px grey

        .item-courier-empty
            color #aaa
            cursor default

    .order-card
        cursor default
        margin 10px 15px
        font-size 13px


    .arrow-icon {
        width: 18px;
        height: 18px;
        opacity 0.8
        // z-index 0
    }

    .arrow-icon > div {
        // opacity 0.6
        margin-left: -4px
        margin-top: -4px;
        transform-origin: center center;
        font: 18px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    }

</style>

<template>
    <div></div>
</template>

<script>
    import * as L from 'leaflet'

    export default {
        data() {
            return {
                markers: [],
                isInit: false,
                popup: null,
                marker_opened: null,
                marker_with_popup: null,
                marker_over: false,
            }
        },
        props: {
            data: {
                type: Array,
                default: null,
            },
            map: {
                type: Object,
                default: null,
            },
        },
        watch: {
            map: {
                handler: function (val, oldVal) {
                    if (!this.isInit && val) {
                        this.isInit = true
                        this.map.on('popupclose', this.onMapPopupClose)
                        this.map.on('popupopen', this.onMapPopupOpen)
                        this.map.on('click', this.onMapMouseClick)
                        this.create()
                    }
                }
            },
            data: {
                deep: true,
                handler: function (val, oldVal) {

                    if (!this.isInit) return

                    console.log('val', val)
                    console.log('oldVal', oldVal)

                    let diff = false
                    if (val.length != this.markers.length) {
                        diff = true
                    } else {
                        for (let i = 0; i < val.length; i++) {
                            if (val[i].id != oldVal[i].id) {
                                diff = true
                                break
                            }
                        }
                    }

                    if (diff) {
                        console.log('recreate icons')
                        this.delete()
                        this.create()
                    } else {

                        const data = this.data
                    
                        this.markers.forEach((marker, i) => {
                            const item = data[i]
                            if (item.lat && item.lon) {
                                marker.setLatLng([item.lat, item.lon])
                                marker.options.data = item.options.data
                            }

                            if (marker) {
                                marker._icon.innerHTML = this.iconHtmlContent(item)
                                // marker.setTooltipContent(item.tooltip)
                                marker.update()
                            }
                            if (marker == this.marker_opened) {
                                this.popup.setContent(marker.content())
                                this.popup.setLatLng([item.lat, item.lon])
                                this.popup.update()
                            }


                        })
                    }
                },
            }
        },
        destroyed() {
            this.map.off('popupclose')
            this.map.off('popupopen')
            this.map.off('click')
            this.delete()
            this.isInit = false
        },
        methods: {
            iconHtmlContent(data) {
                return `<div class="map-label"><div class="map-label-content" style="border-color: ${ data.color };color: ${ data.color };">${ data.content }</div><div class="map-label-arrow" style="border-top-color: ${ data.color };"></div></div>`
            },
            delete() {
                for (const marker of this.markers) {
                    marker.off('click')
                    marker.off('mouseover')
                    marker.off('mouseout')
                    this.map.removeLayer(marker)
                }
                this.markers = []
            },

            create() {
                for (const item of this.data) {
                    if (item.lat && item.lon) {
                        var pos = new L.LatLng(item.lat, item.lon)
                        var icon = L.divIcon({
                            iconSize: null,
                            html: this.iconHtmlContent(item)
                        })
                        item.options.icon = icon
                        item.options.popup_open = false
                        item.options.popup_fix = false
                        item.options.zIndexOffset = 2000
                        item.options.offset_orig = 2000
                        const marker = L.marker(pos, item.options)
                        marker.addTo(this.map)
                        if (item.tooltip) {
                            marker.on('click', this.onMarkerMouseClick)
                            marker.on('mouseover', this.onMarkerMouseOver)
                            marker.on('mouseout', this.onMarkerMouseOut)
                            marker.content = item.tooltip
                        }
                        this.markers.push(marker)
                    }
                }
            },

            onMapMouseClick(e) {
                if (e.originalEvent.target.nodeName !== 'path') {
                    if (this.marker_opened) {
                        this.popupClose(this.marker_opened, true)
                    }
                }
            },

            // События с попапами (открытие и закрытие)
            onMapPopupClose(e) {
                // Событие закрытия отрытого попапа на карте (e)
                if (!this.marker_over) {
                    if (this.marker_opened) this.popupClose(this.marker_opened, false)
                }
            },

            onMapPopupOpen(e) {
                this.marker_with_popup = this.marker_opened
            },
            
            popupOpen(marker, autopan, offsety) {
                let option
                // if (autopan) {
                //     option = {
                //         offset: [0, -offsety],
                //         autoPanPaddingTopLeft: [50, 90],
                //         autoPanPaddingBottomRight: [50, 0],
                //         closeButton: false,
                //         closeOnClick: false,
                //         className: 'map-icons',
                //     }
                // } else {
                    option = {
                        offset: [0, -offsety],
                        autoPan: false,
                        closeButton: false,
                        closeOnClick: false,
                        className: 'map-icons',
                    }
                // }
                this.popup = L.popup(option)
                    .setLatLng(marker.getLatLng())
                    .setContent(marker.content())
                    .openOn(this.map)

                marker.options.popup_open = true
                marker.options.popup_fix = false
                this.marker_opened = marker
                // this.object_selected = marker.options.oid
                if (marker.setZIndexOffset) marker.setZIndexOffset(5000)
            },

            popupClose(marker, popup_close) {
                if (marker) {
                    // if (marker.options.micon) marker.setIcon(marker.options.micon)
                    if (popup_close) this.map.closePopup(this.popup)
                    marker.options.popup_open = false
                    marker.options.popup_fix = false
                    if (marker.setZIndexOffset) marker.setZIndexOffset(marker.options.offset_orig)
                    this.marker_opened = null
                    // this.object_selected = 0
                }
            },

            popupFixChange(marker, offsety) {
                if (marker.options.micon) {
                    offsety = marker.options.icon.options.iconAnchor[1] + 1
                    if (marker.options.rotationAngle && marker.options.rotationAngle == 180) offsety = 6
                }
                if (marker.options.popup_fix) {
                    // Попап закреплен - убираем закрепление и кнопку закрытия на попапе
                    this.popup = L.popup({
                        offset: [0, -offsety],
                        autoPan: false,
                        closeButton: false,
                        closeOnClick: false,
                        className: 'map-icons',
                    })
                    .setLatLng(marker.getLatLng())
                    .setContent(marker.content())
                    .openOn(this.map)
                    marker.options.popup_fix = false
                } else {
                    // Попап не закреплен - устанавливаем закрепление и кнопку закрытия на попапе
                    this.popup = L.popup({
                        offset: [0, -offsety],
                        autoPan: false,
                        closeButton: true,
                        closeOnClick: false,
                        className: 'map-icons',
                    })
                    .setLatLng(marker.getLatLng())
                    .setContent(marker.content())
                    .openOn(this.map)
                    marker.options.popup_fix = true
                }
            },

            onMarkerMouseClick(e) {
                // if (e.target.options.oid) {
                //     let index = e.target.options.index
                //     let object_index = this.couriers.findIndex(courier => courier.object.oid == this.object_selected)

                //     if (index != object_index) {
                //         this.popup_close(this.marker_opened, true)
                //     }
                    var offsety = 18
                    if (this.marker_over) {
                        if (this.marker_opened && e.target == this.marker_opened) this.popupFixChange(e.target, offsety)
                        else {
                            this.popupClose(this.marker_opened, true)
                            this.popupOpen(e.target, false, offsety)
                            this.popupFixChange(e.target, offsety)
                        }
                    } else {
                        // Only for tap of touch
                        if (this.marker_opened && e.target == this.marker_opened) this.popupClose(this.marker_opened, true)
                        else this.popupOpen(e.target, false, offsety)
                    }
                // }
            },

            onMarkerMouseOver(e) {
                if (!this.marker_over) {
                    this.marker_over = true
                }
                if (e.target.options.popup_open) {
                    // Наводим на маркер с открытым попапом
                } else {
                    var offsety = 18
                    // Наводим на маркер с закрытым попапом
                    if (!this.marker_opened || this.marker_opened.options.popup_fix == false) {
                        // если не зафиксирован открытый попап - открываем попап без кнопки закрытия
                        if (this.marker_opened) this.popupClose(this.marker_opened, true)
                        this.popupOpen(e.target, false, offsety)
                    }
                }
            },

            onMarkerMouseOut(e) {
                if (e.target.options.popup_open) {
                    // Убираем указатель мыши с маркера с открытым попапом
                    if (!e.target.options.popup_fix) {
                        // Попап не зафиксирован - закрываем его попап и убираем фиксацию
                        this.popupClose(e.target, true)
                    }
                    if (this.marker_over) {
                        this.marker_over = false
                    }
                }
            },
        },
    }
</script>

<style lang="stylus">
    
    .map-label
        position absolute
        bottom 0
        // left -50%
        display flex
        flex-direction column
        text-align center
        // pointer-events none

    .map-label-content
        order 1
        position relative
        left -50%
        background-color #fff
        border-radius 5px
        border-width 2px
        border-style solid
        border-color #444
        padding 0px 4px
        margin 0
        white-space nowrap
        // pointer-events hover !important

    .map-label-arrow 
        order 2
        width 0px
        height 0px
        left 50%
        border-style solid
        border-color #444 transparent transparent transparent
        border-width 10px 6px 0 6px // [first number is height, second/fourth are right/left width]
        margin-left -6px

    .map-label.inactive
        opacity 0.5

    .map-icons .leaflet-popup-tip-container
        width 30px
        height 15px

    .map-icons .leaflet-popup-tip
        background transparent
        border none
        box-shadow none



</style>

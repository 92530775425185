export default {
    created: function() {
        // this.hello()
    },
    methods: {
        MapObjectHello() {
            console.log('Hello from MapObject')
         }
    },
}
